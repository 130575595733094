import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PREFIX } from '../../Skapa/constants/constants';
import Modal, { ModalBody, ModalHeader, Sheets, SheetSize } from '../../Skapa/Modal/Modal';
import { Actions } from '../../../actions/enums';
import { isCiamEnabled } from '../../../utils/retailUnitsWithCiam';
import Text, { TextTagName } from '../../Skapa/Text/Text';
import Button, { ButtonType } from '../../Skapa/Button/Button';
import Loading, { LoadingBall } from '@ingka/loading';

import styles from './CiamLoginModal.module.scss';
import { useTranslation } from 'react-i18next';
import { getAuthServiceUrl } from '../../../utils/url';
import { getRetailUnitAndLanguage } from '../../../network/utils/getRetailUnitAndLanguage';
import useAuth0 from '../../../hooks/useAuth0';
import { ProfilePagesUrls } from '../../../utils/Urls';
import { splitPath } from '@mvecom/common/utils/splitPath';

export const CiamLoginModal = () => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation('profile');
  const { retailUnit, language } = getRetailUnitAndLanguage();

  const authServiceBaseUrl = getAuthServiceUrl();

  const { isAuthenticated, isLoading } = useAuth0();

  const iframeUrl = useMemo(() => ProfilePagesUrls.menu(), []);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const open = useCallback(() => {
    if (!isCiamEnabled || onProfilePages()) return;
    setVisible((prev) => !prev);
  }, []);

  useEffect(() => {
    window?.ikea?.pubsub?.subscribe(Actions.PROFILE_OPEN_MENU, open);

    return () => {
      window?.ikea?.pubsub?.unsubscribe(Actions.PROFILE_OPEN_MENU, open);
    };
  }, [open]);

  const params = `?lang=${language}&retailUnit=${retailUnit}&returnTo=${window.location.href}`;

  const login = useCallback(() => {
    const authServiceUrl = `${authServiceBaseUrl}/login${params}`;
    window.location.href = authServiceUrl;
  }, [params, authServiceBaseUrl]);

  const signup = useCallback(() => {
    const authServiceUrl = `${authServiceBaseUrl}/signup${params}`;
    window.location.href = authServiceUrl;
  }, [params, authServiceBaseUrl]);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <Wrapper visible={visible} close={close}>
      {isLoading && (
        <Loading prefix={PREFIX} text="">
          <LoadingBall prefix={PREFIX} />
        </Loading>
      )}

      {isAuthenticated && !isLoading && (
        <iframe
          title="profile pages"
          src={iframeUrl}
          className="profilePagesIframe"
          ref={iframeRef}
          sandbox="allow-popups allow-top-navigation allow-same-origin allow-scripts allow-forms"
        />
      )}

      {!isAuthenticated && !isLoading && (
        <div>
          <Text tagName={TextTagName.H2} className={styles.header}>
            {t('loginPrompt.alternativeOption.header')}
          </Text>
          <Text tagName={TextTagName.P}>{t('loginPage.loginPageHeaderDescription')}</Text>
          <Button
            onClick={login}
            className={styles.button}
            prefix={PREFIX}
            fluid
            data-testid="login"
            text={t('loginPage.loginButton')}
            type={ButtonType.PRIMARY}
          />
          <div className={styles.divider}>
            <Text tagName={TextTagName.SPAN}>{t('loginPrompt.newIkea.text')}</Text>
          </div>
          <Button
            onClick={signup}
            className={styles.button}
            prefix={PREFIX}
            fluid
            data-testid="login"
            text={t('loginPage.createAccountButton')}
            type={ButtonType.SECONDARY}
          />
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = ({
  children,
  visible,
  loading,
  title,
  close,
}: {
  children?: ReactNode;
  loading?: boolean;
  visible: boolean;
  title?: string;
  close: () => void;
}) => (
  <Modal prefix={PREFIX} id="menuModal" visible={visible} handleCloseBtn={close}>
    <Sheets
      prefix={PREFIX}
      id="menuSheetsModal"
      header={<ModalHeader title={title} />}
      size={SheetSize.SMALL}
      footer={null}
      preserveAlignment
    >
      <ModalBody prefix={PREFIX} className="skapasModalBody">
        <div className="menuModalBodyContainer">
          {!!loading && (
            <Loading prefix={PREFIX} text="">
              <LoadingBall prefix={PREFIX} />
            </Loading>
          )}
          {children}
        </div>
      </ModalBody>
    </Sheets>
  </Modal>
);

function onProfilePages() {
  const [, , appName] = splitPath(window.location.pathname);
  return appName === 'profile';
}
