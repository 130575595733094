import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import Cookies from 'js-cookie';

import { getCart } from './endpoints/cart/getCart';
import { getSession } from './endpoints/favourites/getSession';
import { Cookie } from './enums';
import { NetworkError as IdentityNetworkError } from './identityProvider/types';
import { RequestTimeout, Type, User } from './types';
import { backendUrl } from './utils/getBackendUrl';
import { getCookieDomain } from './utils/getCookieDomain';
import { getRetailUnitCookiePath } from './utils/getRetailUnitCookiePath';
import { Actions } from '../actions/enums';
import { getAuth0Session } from './endpoints/profile-pages/getAuth0Session';
import { getEnv } from '../utils/env';
import { getRetailUnitAndLanguage } from './utils/getRetailUnitAndLanguage';

const AXIOS_RETRIES = 3;

class CustomAxiosError extends Error {
  constructor(
    public message: string,
    public type: string,
    public traceId: string,
    public status?: number,
  ) {
    super(message);
    Object.setPrototypeOf(this, CustomAxiosError.prototype);
  }
}

const handleAxiosError = (error: AxiosError<CartAxiosError | FavouritesAxiosError>): never => {
  const { response, code } = error;
  const status = response?.status;
  const message = response?.data?.error?.message ?? `Error status: ${status}`;
  const type = response?.data?.error?.type ?? code ?? 'unknown';
  const traceId = response?.data?.error?.traceId ?? '';

  throw new CustomAxiosError(message, type, traceId, status);
};

export interface CartAxiosError {
  status: number;
  error: {
    type: string;
    message: string;
    traceId: string;
  };
}

export interface FavouritesAxiosError {
  status: number;
  error: {
    type: string;
    message: string;
    traceId: string;
  };
}

export enum NetworkError {
  CONNECTION_ABORTED = 'ECONNABORTED',
}

const setCookies = (user: User) => {
  if (user.type === Type.GUEST) {
    Cookies.set(Cookie.FAVOURITES_GUEST_ID, user.id, {
      domain: getCookieDomain,
      path: getRetailUnitCookiePath,
    });
    Cookies.set(Cookie.FAVOURITES_GUEST_PROVIDER, user.provider, {
      domain: getCookieDomain,
      path: getRetailUnitCookiePath,
    });
    Cookies.set(Cookie.CART_GUEST_ID, user.id, { domain: getCookieDomain, path: getRetailUnitCookiePath });
    Cookies.set(Cookie.CART_GUEST_PROVIDER, user.provider, {
      domain: getCookieDomain,
      path: getRetailUnitCookiePath,
    });
    Cookies.remove(Cookie.USER_LOGGEDIN, { domain: getCookieDomain, path: getRetailUnitCookiePath });
    Cookies.remove(Cookie.IKEA_SESSION, { domain: getCookieDomain, path: getRetailUnitCookiePath });
  } else if (Cookies.get(Cookie.USER_LOGGEDIN) !== 'true') {
    Cookies.set(Cookie.USER_LOGGEDIN, 'true', { domain: getCookieDomain, path: getRetailUnitCookiePath });
    window?.ikea?.pubsub?.publish?.(Actions.USER_INFO_AVAILABLE);
  }
};

export const setupAuth0Session = async () => {
  const sessionStatus = await getAuth0Session();

  if (!sessionStatus.isAuthenticated) {
    const { results: user } = await getSession();
    setCookies(user);
  }

  return sessionStatus;
};

export const setupSession = async () => {
  const { results: user } = await getSession();
  setCookies(user);
  return user;
};

export const axiosRetryDefault = async (error: AxiosError<CartAxiosError | FavouritesAxiosError>) => {
  if (
    error.response?.data.error.type === IdentityNetworkError.IRW_COOKIE_MISSING ||
    error.response?.data.error.type === IdentityNetworkError.NO_ACCESS
  ) {
    await setupSession();
    return true;
  }
  if (
    error.response?.data.error.type === IdentityNetworkError.CART_NOT_FOUND ||
    error.response?.data.error.type === IdentityNetworkError.LINE_NOT_FOUND
  ) {
    await setupSession();
    await getCart({ queryParams: { skipSync: true, includePrices: false } });
    return true;
  }
  if (error.code === NetworkError.CONNECTION_ABORTED) return true;
  if ((error.response?.status as number) >= 500) return true;
  return false;
};

export const cartAxiosInstance = ((): AxiosInstance => {
  const cartAxios = axios.create({
    baseURL: backendUrl(),
    timeout: RequestTimeout.XXL,
    withCredentials: true,
  });

  axiosRetry(cartAxios, {
    retries: AXIOS_RETRIES,
    shouldResetTimeout: true,
    retryDelay: (retryCount) => Math.floor(Math.random() * exponentialDelay(retryCount)),
    retryCondition: (error: AxiosError<any>) => axiosRetryDefault(error),
  });

  cartAxios.interceptors.request.use(
    (config) => config,
    (error: AxiosError) => Promise.reject(error),
  );
  cartAxios.interceptors.response.use(
    (res: AxiosResponse) => res,
    (error: AxiosError<CartAxiosError>) => handleAxiosError(error),
  );

  return cartAxios;
})();

export const favouritesAxiosInstance = ((): AxiosInstance => {
  const favouritesAxios = axios.create({
    baseURL: backendUrl(),
    timeout: RequestTimeout.XXL,
    withCredentials: true,
  });

  axiosRetry(favouritesAxios, {
    retries: AXIOS_RETRIES,
    shouldResetTimeout: true,
    retryDelay: (retryCount) => Math.floor(Math.random() * exponentialDelay(retryCount)),
    retryCondition: (error: AxiosError<any>) => axiosRetryDefault(error),
  });

  favouritesAxios.interceptors.request.use(
    (config) => config,
    (error: AxiosError) => Promise.reject(error),
  );
  favouritesAxios.interceptors.response.use(
    (res: AxiosResponse) => res,
    (error: AxiosError<FavouritesAxiosError>) => handleAxiosError(error),
  );

  return favouritesAxios;
})();

export const profileAxiosInstance = ((): AxiosInstance => {
  const profileAxios = axios.create({
    baseURL: backendUrl(),
    timeout: RequestTimeout.XXL,
    withCredentials: true,
  });

  axiosRetry(profileAxios, {
    retries: AXIOS_RETRIES,
    shouldResetTimeout: true,
    retryDelay: (retryCount) => Math.floor(Math.random() * exponentialDelay(retryCount)),
    retryCondition: (error: AxiosError<any>) => axiosRetryDefault(error),
  });

  return profileAxios;
})();

export const authAxiosInstance = ((): AxiosInstance => {
  const baseURL = (() => {
    const { retailUnit, language } = getRetailUnitAndLanguage();
    const basePath = `/${retailUnit}/${language}`;
    const env = getEnv();
    if (env === 'qa') {
      return `https://www.cte.ikeadt.com${basePath}`;
    } else if (env === 'production') {
      return `https://www.ikea.com${basePath}`;
    }
    return `${window.location.origin}${basePath}`;
  })();

  const authAxios = axios.create({
    baseURL,
    timeout: RequestTimeout.XXL,
    withCredentials: true,
  });

  axiosRetry(authAxios, {
    retries: AXIOS_RETRIES,
    shouldResetTimeout: true,
    retryDelay: (retryCount) => Math.floor(Math.random() * exponentialDelay(retryCount)),
    retryCondition: (error: AxiosError<any>) => axiosRetryDefault(error),
  });

  return authAxios;
})();
